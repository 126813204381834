import React, { useEffect, useRef, useState, Fragment, useMemo } from "react";
import { GatsbyImage } from 'gatsby-plugin-image'
import { graphql, navigate } from 'gatsby';
import parse from 'html-react-parser'
import GoogleMapReact from 'google-map-react';

import { Popover } from '@headlessui/react'
import { usePopper } from 'react-popper'

import Layout from '../components/layout'
import Seo from '../components/seo'
import EventCard from "../components/template-parts/event-card";

import Marker from '../svg/marker.svg'
import Calendar from '../svg/calendar.svg'
import List from '../svg/list.svg'
import Search from '../svg/search.svg'
import Filter from '../svg/filter.svg'

import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'

import DatePicker from 'react-date-picker'

import '../scss/events.scss'

import { mapStyles } from '../utils/map-styles'

const MarkerComponent = ({ event, handleEvent }) => {

    let [referenceElement, setReferenceElement] = useState()
    let [popperElement, setPopperElement] = useState()
    let { styles, attributes } = usePopper(referenceElement, popperElement, {
        placement: 'auto',
    })

    function eventHandle(e, event) {
        const isBrowser = typeof window !== 'undefined'

        if (isBrowser) {
            if (window?.innerWidth < 768) {
                console.log('mobile click?')
                handleEvent(event)
            }
        }
    }

    const navigateToEvent = () => {
        if (typeof window !== 'undefined') {
            window.history.pushState({}, '', '/events?event=' + event.slug);
            handleEvent(event)
        }
    }

    return (
        <Popover className="relative" onClick={(e) => eventHandle(e, event)}>
            <div className="button-wrap" onClick={navigateToEvent}>
                <Popover.Button style={{ backgroundColor: 'transparent', border: 'none', zIndex: '1' }}  ref={setReferenceElement}>
                    <Marker />
                </Popover.Button>
            </div>
            <Popover.Panel ref={setPopperElement} style={styles.popper} {...attributes.popper}>
                <EventCard event={event} minWidth={'350px'} handleClick={handleEvent} />
            </Popover.Panel>
        </Popover>
    )
}


const MapComponent = ({ events, handleEventSelect }) => {
    // Remove date-based filtering to show all events
    let windowWidth = 0;
    const isBrowser = typeof window !== 'undefined'

    if (isBrowser) {
        windowWidth = window.innerWidth
    }

    const center = {
        lat: 40.6326371,
        lng: -102.0582287
    }

    const zoom = windowWidth && windowWidth > 1200 ? 5 : 3

    const [map, setMap] = useState(null);

    const onLoad = (map) => {
        setMap(map)
    }

    useEffect(() => {
        if (map && isBrowser && events.length) {
          const bounds = new window.google.maps.LatLngBounds();
          events.forEach(event => {
            let lat = (event?.eventData?.location?.latitude) ? event?.eventData?.location?.latitude : 40.6326371
            let lng = (event?.eventData?.location?.longitude) ? event?.eventData?.location?.longitude : -102.0582287

            bounds.extend({
              lat: lat,
              lng: lng,
            });
          });
          map.fitBounds(bounds);
        }
    }, [map, events, isBrowser]);

    return (
        <GoogleMapReact
            onGoogleApiLoaded={({ map }) => onLoad(map)}
            bootstrapURLKeys={{ key: 'AIzaSyARyyxgNKwte6EdgdaDdEtI2c6X2Vvc2dQ' }}
            defaultCenter={center}
            defaultZoom={zoom}
            options={{
                styles: mapStyles,
                zoomControl: false,
                fullscreenControl: false,
                maxZoom: 15,
                gestureHandling: 'greedy',
            }}>
            {events?.length && events.map((event) => (
                <MarkerComponent
                    key={event.id}
                    lat={event?.eventData?.location?.latitude}
                    lng={event?.eventData?.location?.longitude}
                    event={event}
                    handleEvent={handleEventSelect}
                />
            ))}
        </GoogleMapReact>
    )
}

const Sidebar = ({ categories, view, changeSearch, changeCategory, changeView, changeDate }) => {

    const [search, setSearch] = useState('')
    const [selectedView, setSelectedView] = useState(view)

    const [showSearch, setShowSearch] = useState(false)
    const [showFilter, setShowFilter] = useState(false)

    const handleSearchChange = (e) => {
        const value = e.target.value
        setSearch(value)
        changeSearch(value)
    }

    const handleCategoryChange = (e) => {
        changeCategory(e.target)
    }

    const handleToggleMap = () => {
        if (selectedView === 'dayGridMonth') {
            changeView('listMonth')
            setSelectedView('listMonth')
        } else {
            changeView('dayGridMonth')
            setSelectedView('dayGridMonth')
        }
    }

    const handleToggleSearch = () => {
        setShowSearch(!showSearch)
    }

    const handleToggleFilter = () => {
        setShowFilter(!showFilter)
    }

    const [dateValue, onChange] = useState(null);

    useEffect(() => {
        dateValue && changeDate(dateValue)
    }, [dateValue])

    return (
        <div className="sidebar">

            <div className="mobile-tools">
                <div className="view-select">
                    <button onClick={() => handleToggleMap()}>
                        {selectedView === 'dayGridMonth' ? (
                            <List className="icon" />
                            ) : (
                            <Calendar className="icon" />
                        )}
                    </button>
                </div>
                <div className="filter-toggle">
                    <div className="search-button">
                        <button onClick={handleToggleSearch}>
                            <Search className="icon" />
                        </button>
                    </div>
                    <div className="category-button">
                        <button onClick={handleToggleFilter}>
                            <Filter className="icon" />
                        </button>
                    </div>
                </div>
            </div>

            <div className={`search ${showSearch ? 'active' : ''}`}>
                <Search className="icon" />
                <input type="search" placeholder="Search" value={search} onChange={(e) => handleSearchChange(e)} />
            </div>
            
            <div className="date-selector">
                <DatePicker onChange={onChange} value={dateValue} maxDetail={'year'} />
                {/* <button>
                    <Calendar className="icon" />
                    Date
                </button> */}
            </div>

            <div className={`filters ${showFilter ? 'active' : ''}`}>
                <div className="dates">
                    <h3>
                        Date
                    </h3>
                    <div className="date-selector">
                        <DatePicker onChange={onChange} value={dateValue} maxDetail={'year'} />
                    </div>
                </div>
                <h3>
                    Filter
                </h3>
                {categories?.length && categories.map(category => (
                    <label>
                        <div className="checkbox-container">
                            <input type="checkbox" name="categories" onChange={(e) => handleCategoryChange(e)} value={category.slug} />
                            <span>
                                {category?.name}
                            </span>
                        </div>
                    </label>
                ))}
            </div>
        </div>
    )
}

const EventCalendar = ({ events, selectedView, calRef, handleEventSelect }) => {
    return (
        <div style={{ display: selectedView === 'dayGridMonth' ? 'block' : 'none'}}>
            <FullCalendar
                ref={calRef}
                plugins={[ dayGridPlugin ]}
                initialView="dayGridMonth"
                dayHeaderFormat={{ weekday: 'short' }}
                headerToolbar={{
                    left: '',
                    center: '',
                    right: ''
                }}
                contentHeight={'auto'}
                eventClick={(arg) => {
                    handleEventSelect(arg.event.extendedProps.event)
                }}
                events={events.map(event => {
                    const startDate = new Date(event.eventData.startDate)
                    const endDate = new Date(event.eventData.endDate)
                    return {
                        title: event.title,
                        start: startDate,
                        end: endDate,
                        extendedProps: {
                            event: event
                        }
                    }
                })}
            />
        </div>
    )
}

const EventGrid = ({ events, selectedView, date, search, handleEventSelect }) => {
    const [displayEvents, setDisplayEvents] = useState([])

    const handleSelectEvent = (event) => {
        handleEventSelect(event)
    }

    useEffect(() => {
        // Filter events only by the selected month/year
        const filtered = events.filter(event => {
            const month = date.getMonth()
            const year = date.getFullYear()
            const eDate = new Date(event.eventData.startDate)
            const eMonth = eDate.getMonth()
            const eYear = eDate.getFullYear()
            return month === eMonth && year === eYear
        })
        setDisplayEvents(filtered)

        // Handle URL parameters for direct event selection
        if (typeof window !== 'undefined') {
            const urlString = window.location.href;
            const url = new URL(urlString);
            const e = url.searchParams.get('event');

            if (e !== null && e.length) {
                const currentEvent = events.find(event => event.slug === e);
                if (currentEvent) {
                    handleEventSelect(currentEvent)
                }
            }
        }
    }, [date, events])

    return (
        <div className="event-grid" style={{ display: selectedView === 'listMonth' ? 'grid' : 'none'}}>
            {displayEvents?.length ? displayEvents.map((event) => (
                <EventCard key={event.id} event={event} handleClick={handleSelectEvent} />
            )) : (
                <div className="empty-state">
                    {search ? (
                        <p>
                            We can't seem to find information for that event. Try your search again or reach out to our team with any questions.
                        </p>
                    ) : (
                        <p>
                            There are no events available during this time.
                        </p>
                    )}
                </div>
            )}
        </div>
    )
}

const SelectedEvent = ({ event, removeSelectedEvent }) => {

    const handleRemoveSelectedEvent = () => {
        removeSelectedEvent(true)
        // navigate('/events')
    }

    return (
        <div className="selected-event">
            <div className="breadcrumbs">
                <button onClick={handleRemoveSelectedEvent}>
                    <span>&laquo;</span> Back
                </button>
            </div>
            <div className="selected-event-container">
                <div className="image">
                    {event?.featuredImage?.image?.localFile?.childImageSharp?.gatsbyImageData && (
                        <GatsbyImage
                            objectFit="contain"
                            alt={event?.featuredImage?.image?.altText ? event?.featuredImage?.image?.altText : event?.title}
                            image={event?.featuredImage?.image?.localFile?.childImageSharp?.gatsbyImageData}
                        />
                    )}
                </div>
                <div className="content">
                    <h3>
                        {event?.title}
                    </h3>
                    {event?.content && parse(event?.content)}
                    <div className="line">
                        <strong>
                            Where:
                        </strong>
                        <span>
                            {event?.eventData?.location?.streetNumber && event?.eventData?.location?.streetName && (
                                <Fragment>
                                    {event?.eventData?.location?.streetNumber} {event?.eventData?.location?.streetName},&nbsp;
                                </Fragment>
                            )}
                            {event?.eventData?.location?.city && event?.eventData?.location?.stateShort && (
                                <Fragment>
                                    {event?.eventData?.location?.city}, {event?.eventData?.location?.stateShort} 
                                </Fragment>
                            )}
                        </span>
                    </div>
                    <div className="line">
                        <strong>
                            When:
                        </strong>
                        <span>
                            {event?.eventData?.startDate && event?.eventData?.endDate && (
                                <Fragment>
                                    {event?.eventData?.startDate} - {event?.eventData?.endDate}
                                </Fragment>
                            )}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
}

const EventsTemplate = ({ data: { allWpEvent, allWpEventCategory } }) => {
    const { events } = allWpEvent
    const { categories } = allWpEventCategory

    const months = useMemo(() => [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
    ], [])

    const [filteredEvents, setFilteredEvents] = useState([])
    const [selectedView, setSelectedView] = useState('dayGridMonth')
    const [dateTitle, setDateTitle] = useState()
    const [date, setDate] = useState(new Date())
    const [selectedEvent, setSelectedEvent] = useState()
    const [search, setSearch] = useState('');
    const [selectedCategories, setSelectedCategories] = useState([]);

    const calendarRef = useRef(null)
    const eventTable = useRef(null)

    // Initialize events
    useEffect(() => {
        if (events) {
            setFilteredEvents(events)
        }
    }, [events])

    // Update date title
    useEffect(() => {
        const month = date.getMonth()
        const year = date.getFullYear()
        setDateTitle(`${months[month]} ${year}`)
    }, [date, months])

    // Handle search and category filtering
    useEffect(() => {
        let updatedEvents = events;

        // Apply search filter across all events
        if (search) {
            updatedEvents = updatedEvents.filter(event =>
                JSON.stringify(event).toLowerCase().trim().includes(search.toLowerCase().trim())
            )
        }

        // Apply category filter
        if (selectedCategories?.length) {
            updatedEvents = updatedEvents.filter(event =>
                event.eventCategories.categories.some(cat =>
                    selectedCategories.includes(cat.slug)
                )
            )
        }

        setFilteredEvents(updatedEvents);
    }, [search, selectedCategories, events])

    const toggleView = (view) => setSelectedView(view)

    const prevMonth = () => {
        eventTable.current.scrollIntoView();
        calendarRef.current.getApi().prev()
        const date = calendarRef.current.getApi().getDate();
        setDate(new Date(date));
    }

    const nextMonth = () => {
        eventTable.current.scrollIntoView();
        calendarRef.current.getApi().next()
        const date = calendarRef.current.getApi().getDate();
        setDate(new Date(date));
    }

    const handleSearchChange = (value) => setSearch(value)

    const handleCategoryChange = (target) => {
        const value = target.value
        if (target.checked) {
            setSelectedCategories([...selectedCategories, value])
        } else {
            setSelectedCategories(selectedCategories.filter(cat => cat !== value))
        }
    }

    const handleDateChange = (date) => {
        setDate(new Date(date));
        calendarRef.current.getApi().gotoDate(date)
    }

    const selectEvent = (event) => setSelectedEvent(event)

    const handleRemoveSelectedEvent = () => {
        setSelectedEvent(null)
        if (typeof window !== 'undefined') {
            const urlString = window.location.href;
            const url = new URL(urlString);
            const e = url.searchParams.get('event');

            if (e !== null && e.length) {
                window.history.pushState({}, document.title, '/events' );
            }
        }
    }

    return (
        <Layout isProductPage={true}>
            <Seo title={'Events'} description={'Mission 22 events calendar'} />
            <section className="map-container">
                <MapComponent
                    events={filteredEvents}
                    handleEventSelect={selectEvent}
                />
            </section>

            <section className="events-wrapper" ref={eventTable}>
                <Sidebar
                    categories={categories}
                    view={selectedView}
                    changeView={toggleView}
                    changeSearch={handleSearchChange}
                    changeCategory={handleCategoryChange}
                    changeDate={handleDateChange}
                />

                <div className="events">
                    {selectedEvent ? (
                        <SelectedEvent
                            event={selectedEvent}
                            removeSelectedEvent={handleRemoveSelectedEvent}
                        />
                    ) : (
                        <Fragment>
                            <div className="events-header">
                                <div className="view-toggle">
                                    <button
                                        onClick={() => toggleView('dayGridMonth')}
                                        className={selectedView === 'dayGridMonth' ? 'active' : ''}
                                    >
                                        <Calendar className="icon" /> Month
                                    </button>
                                    <button
                                        onClick={() => toggleView('listMonth')}
                                        className={selectedView === 'listMonth' ? 'active' : ''}
                                    >
                                        <List className="icon" /> List
                                    </button>
                                </div>
                                <h2>{dateTitle} Events</h2>
                            </div>
                            <div className="events-calendar">
                                <EventCalendar
                                    events={filteredEvents}
                                    selectedView={selectedView}
                                    calRef={calendarRef}
                                    date={date}
                                    handleEventSelect={selectEvent}
                                />
                                <EventGrid
                                    events={filteredEvents}
                                    selectedView={selectedView}
                                    date={date}
                                    search={search}
                                    handleEventSelect={selectEvent}
                                />
                            </div>
                            <div className="calendar-footer">
                                <button onClick={prevMonth}>&laquo; Prev</button>
                                <button onClick={nextMonth}>Next &raquo;</button>
                            </div>
                        </Fragment>
                    )}
                </div>
            </section>
        </Layout>
    )
}

export default EventsTemplate

export const query = graphql`
    query {
        allWpEvent {
            events: nodes {
                slug
                id
                title
                content
                eventCategories {
                    categories: nodes {
                        slug
                    }
                }
                eventData {
                    startDate
                    endDate
                    location {
                        city
                        latitude
                        longitude
                        state
                        postCode
                        streetAddress
                        streetName
                        streetNumber
                        stateShort
                        country
                    }
                }
                featuredImage {
                    image: node {
                        localFile {
                            childImageSharp {
                                gatsbyImageData(
                                    layout: FULL_WIDTH,
                                    aspectRatio: 1,
                                    placeholder: BLURRED,
                                    formats: [AUTO]
                                )
                            }
                        }
                    }
                }
            }
        }
        allWpEventCategory {
            categories: nodes {
                slug
                name
            }
        }
    }
`
